.root {
	overflow-y: auto;
	overflow-x: hidden;
	scroll-behavior: smooth;
}

.header {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: var(--vkws_landings--header--z-index);
	background-color: var(--vkws_landing--advanced-menu--bg-color);
	transform: translate(0, 0);
	transition: transform var(--vkws_landings--transition--duration) var(--vkws_landings--transition--timing-function);
}

.header-hidden {
	transform: translate(0, -100%);
	transition: transform var(--vkws_landings--transition--duration) var(--vkws_landings--transition--timing-function);
}

.content {
	padding-top: calc(var(--vkws_landings--portal_header--height) + var(--vkws_landings--advanced_header--mobile-height));
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: stretch;
	justify-content: space-between;
}

@media screen and (min-width: 1024px) {
	.content {
		padding-top: calc(var(--vkws_landings--portal_header--height) + var(--vkws_landings--advanced_header--desktop-height));
	}
}
