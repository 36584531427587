.root {
	padding: 6px 16px 8px;
	font-size: 18px;
	outline: none;
	font-style: normal;
	font-weight: 400;
	line-height: 26px;
	height: auto;
	border-radius: 8px;
	backdrop-filter: blur(18px);
	background: var(--vkws_landings--header_button_project--bg);
	color: var(--vkws_landings--color--white);
}

.root:hover {
	background: var(--vkws_landings--header_button_project--bg--hover);
}

.root:active {
	background: var(--vkws_landings--header_button_project--bg--active);

}

@media screen and (min-width: 768px) {
	.root {
		display: inline-flex;
	}
}
