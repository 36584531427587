.root {
	display: flex;
	justify-content: flex-start;
    align-items: flex-start;
	padding: 12px;
	border-radius: 12px;
	width: 334px;
	min-height: 81px;
}

.root:hover,
.root-active {
	background: var(--vkws_landing--toogle-menu-item--bg_hover);
}

.icon {
	padding-top: 3px;
	margin-right: 12px;
	color: var(--vkws_landing--toogle-menu-item-icon--color);
}

.root-active>.icon,
.root:hover>.icon {
	color: var(--vkws_landing--toogle-menu-item-icon--color_active);
}

.icon svg {
	width: 28px;
	height: 28px;
}

.text {
	font-style: normal;
}

.title {
	font-weight: 600;
	font-size: 17px;
	line-height: 1.4em;
	color: var(--vkws_landing--toogle-menu-item-title--color);
}

.root-active .title,
.root:hover .title {
	color: var(--vkws_landing--toogle-menu-item-title--color_active);
}

.desc {
	font-weight: 300;
	font-size: 14px;
	line-height: 1.2em;
	color: var(--vkws_landing--toogle-menu-item-desc--color);
}

.root-active .desc,
.root:hover .desc {
	color: var(--vkws_landing--toogle-menu-item-desc--color_active);
}
