.root {
	display: flex;
	flex-flow: row nowrap;
	align-items: center;
	justify-content: flex-start;
	list-style: none;
	line-height: 32px;
	padding: 0 16px;
	transition: background-color 0.15s;
	position: relative;
	cursor: pointer;
	height: 82px;
}
