.root {
	position: inherit;
	display: inline-flex;
	flex-flow: column nowrap;
	flex: 0 1 auto;
	vertical-align: middle;
}

.element.element {
	z-index: var(--vkws_landings--dropdown-menu--z-index);
}
