.agreement-block {
	display: grid;
	grid-template-columns: 16px 1fr;
	gap: 8px;
	margin-bottom: 10px;
}

.icon {
	width: 16px;
	height: 16px;
	cursor: pointer;
	margin-top: 3px;
}

.no-agreement {
	width: 100%;
	height: 100%;
	border-radius: 4px;
	border: var(--vkws_landings--checkbox--border);
	background: var(--vkws_landings--color--white);
}

.agreement-text {
	margin-right: -5px;
	color: var(--vkws_landings--color--light-slate-gray);
	font-family: "VK Sans Display", "Proxima Nova", Arial, Helvetica, "Helvetica Neue", sans-serif;
	font-size: 13px;
	font-style: normal;
	font-weight: 400;
	line-height: 140%;
}

.agreement-text a {
	text-decoration: none;
	color: var(--vkws_landings--link-blue--color);
}

@media screen and (min-width: 1024px) {
	.agreement-block {
		gap: 8px;
	}

	.icon {
		margin-top: 0;
	}

	.agreement-text {
		font-size: 12px;
	}
}

@media screen and (min-width: 1440px) {
	.agreement-block {
		gap: 9px;
	}

	.icon {
		margin-top: 3px;
	}

	.agreement-text {
		font-size: 16px;
	}
}
