.root {
	width: 100%;
	border-radius: 8px;
	border: 1px solid var(--vkws_landings--goal-block--border-color);
	padding: 12px 19px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: var(--vkws_landings--goal-block--bg);
}

.goal {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	height: 100%;
	font-family: "VK Sans Display", "Proxima Nova", Arial, Helvetica, "Helvetica Neue", sans-serif;
	font-size: 12px;
	font-weight: 500;
	line-height: 15px;
	color: var(--vkws_landings--goal-block--color);
	border-bottom: 1px solid var(--vkws_landings--color--dark-grey);
	padding-right: 5px;
	padding-bottom: 10px;
}

.goal:last-child {
	padding-right: 0;
	border-right: none;
	border-bottom: none;
	padding-bottom: 0;
	padding-top: 10px;
}

.goal a {
	cursor: pointer;
	color: var(--vkws_landings--color--main_blue);
}

.goal a:hover {
	text-decoration-line: underline;
}

@media screen and (min-width: 1024px) {
	.root {
		flex-direction: row;
	}
	.goal {
		flex-direction: row;
		border-right: 1px solid var(--vkws_landings--color--dark-grey);
		border-bottom: none;
		padding-bottom: 0;
		width: 50%;
	}

	.goal:last-child {
		padding-left: 21px;
		padding-top: 0;
	}
}
