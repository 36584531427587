.root {
	position: relative;
	width: 28px;
	height: 28px;
}

.date {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	text-align: center;
	font-size: 13.5px;
	font-style: normal;
	font-weight: 500;
	line-height: 1.0em;
	letter-spacing: -0.135px;
}
