.root {
	display: none;
	height: calc(100vh - var(--vkws_landings--portal_header--height) - 71px);
	padding: 0 0 105px 0;
	position: absolute;
	bottom: 0;
	right: 0;
	left: 0;
	transform: translate(0, 100%);
	z-index: 10;
	overflow: auto;
	background: var(--vkws_landing--advanced-menu--bg-color);
	user-select: none;
}

.root-open {
	display: block;
}

.navbar-wrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 8px;
	padding: 0 12px;
}

.navbar-item {
	width: auto;
	display: flex;
	align-items: center;
	padding: 6px 16px 8px 16px;
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 26px;
	color: var(--vkws_landing--navbar-menu-item--color-dark);
	backdrop-filter: blur(18px);
	border-radius: 8px;
	background: var(--vkws_landings--header_button_light--background_color);
	cursor: pointer;
	opacity: 0.8;
}

.navbar-item:hover {
	opacity: 1;
	background: var(--vkws_landings--header_button_light--background_color--hover);
}

.active-item,
.active-item:hover {
	opacity: 1;
	background: var(--vkws_landings--header_button_light--background_color--active);
}

.content-navbar-item {
	display: flex;
	gap: 12px;
	flex-wrap: wrap;
	margin-top: 12px;
	overflow-y: auto;
	background: var(--vkws_landings--header_content-navbar--background_color-dark);
	border-radius: 20px;
	padding: 10px;
}

.item {
	width: 100%;
	border-radius: 12px;
	padding: 12px 16px;
}

.item:hover {
	background: var(--vkws_landing--toogle-menu-item--bg_hover);
}

.item:active,
.current-menu-item {
	background: var(--vkws_landing--toogle-menu-item--bg_active);
}

@media screen and (min-width: 768px) {
	.navbar-wrapper {
		display: flex;
	}

	.navbar-item {
		font-size: 18px;
	}

	.content-navbar-item {
		margin-top: 24px;
		flex-flow: wrap;
		gap: 16px;
	}

	.item {
		padding: 12px;
		width: 282px;
		border-radius: 12px;
	}
}

@media screen and (min-width: 1200px) {
	.root {
		display: none;
	}
}

@media screen and (min-width: 1440px) {
	.root {
		display: none;
	}

	.root-open {
		display: none;
	}
}
