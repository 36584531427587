.wrapper {
	display: none;
}

.container {
	position: absolute;
	top: 100%;
	width: 100vw;
	max-width: 100vw;
	overflow: hidden;
	font-weight: 500;
	font-size: 15px;
	line-height: 22px;
	color: var(--vkws_landing--navbar-menu-item--color);
	background-color: transparent;
	box-shadow: none;
	border-radius: 0;
}

.onpremise-content {
	overflow-x: hidden;
}

.wrapper-inner-dropdown {
	display: flex;
	padding-top: 20px;
	padding-bottom: 20px;
}

.inner-dropdown {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-template-rows: 1fr 1fr;
	grid-auto-flow: row;
	gap: 4px;
	padding: 18px;
	background-color: var(--vkws_landings--color--raisin-black);
	border-radius: 20px;
	width: 1046px;
}

.inner-dropdown-extra {
	grid-template-rows: 1fr 1fr 1fr;
}

.inner-dropdown-flow-column {
	grid-auto-flow: column;
}

.dropdown-item {
	padding: 0;
}

@media screen and (min-width: 1024px) {
	.wrapper-inner-dropdown {
		max-width: calc(1240px + var(--vkws_landings--horizontal-padding--desktop)* 2);
		padding: 0px var(--vkws_landings--horizontal-padding--desktop);
		margin: 0 auto;
	}

	.container {
		padding-top: 30px;
		transform: translateY(-30px);
	}
}

@media screen and (min-width: 1200px) {
	.wrapper {
		display: flex;
	}
}

@media screen and (min-width: 1440px) {
	.wrapper {
		margin-left: 40px;
		margin-bottom: -5px;
	}

	.wrapper>div {
		padding: 0 6px;
	}

	.wrapper>div:first-child {
		padding-left: 0;
	}

	.wrapper>div:last-child {
		padding-right: 0;
	}
}

@media screen and (min-width: 1540px) {
	.wrapper-inner-dropdown {
		width: 100%;
		max-width: calc(1600px + var(--vkws_landings--horizontal-padding--desktop)* 2);
		padding-left: 100px;
		padding-right: 100px;
	}
}
