.root {
	display: grid;
	grid-template-columns: 1fr;
	gap: 16px;
	margin-bottom: 10px;
}

.title {
	color: var(--vkws_landings--messengers-block-title--color);
	font-family: "VK Sans Display", "Proxima Nova", Arial, Helvetica, "Helvetica Neue", sans-serif;
	font-size: 18px;
	font-style: normal;
	font-weight: 400;
	line-height: 28px;
	opacity: 0.7;
}

.list {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 8px;
}

.item {
	cursor: pointer;
	height: 51px;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	background: var(--vkws_landing--input--bg);
	border: 1px solid var(--vkws_landing--input--border);
}

.item:hover {
	background: var(--vkws_landing--input--bg_hover);
	border: var(--vkws_landing--input--border_hover);
}

.selected,
.selected:hover,
.selected:focus {
	border: 1px solid var(--vkws_landings--color--main_blue);
	background: var(--vkws_landings--color--main_blue);
}

.label {
	display: none;
}

.logo {
	width: 24px;
	height: 25px;
}

@media screen and (min-width: 1024px) {
	.title {
		font-size: 20px;
	}

	.list {
		gap: 15px;
	}

	.item {
		height: 53px;
		gap: 12px;
	}

	.label {
		display: block;
		color: var(--vkws_landings--messengers-block-title--color);
		font-family: "VK Sans Display", "Proxima Nova", Arial, Helvetica, "Helvetica Neue", sans-serif;
		font-size: 18px;
		font-style: normal;
		font-weight: 400;
		line-height: 27px;
		opacity: 0.8;
	}
}

