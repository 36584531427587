.required-wrapper {
	position: relative;
	flex-grow: 1;
}

.required {
	position: absolute;
	top: -10px;
	right: -10px;
	font-size: 20px;
	color: var(--vkws_landing--input--required-star--color);
}

.field-error {
	position: absolute;
	bottom: -24px;
	display: block;
	font-weight: 400;
	font-size: 12px;
	line-height: 24px;
	color: #ff8080;
	white-space: nowrap;
}

.appearance_blue .field-error {
	color: var(--vkws_landing--input--required-star-light--color);
}

.appearance_blue .required {
	color: var(--vkws_landing--required-blue--color);
	opacity: 0.5;
}
