.root {
	padding-top: 20px;
	padding-bottom: 20px;
	background-color: var(--vkws_landing--advanced-menu--bg-color);
}

@media screen and (min-width: 768px) {
	.root {
		padding-top: 24px;
		padding-bottom: 24px;
	}
}

@media screen and (min-width: 1540px) {
	.root {
		width: 100%;
		max-width: calc(1600px + var(--vkws_landings--horizontal-padding--desktop) * 2);
		padding: 24px 100px;
	}
}
