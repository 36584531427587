.root {
	max-width: 320px;
	border-radius: 12px;
	background-color: #fff;
	box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.16);

	transition-duration: 0.1s;
	transition-property: opacity, visibility;

	padding: 0;
	margin: 0;
	position: absolute;
	z-index: 1;
}

.root_state_hidden, .root[aria-hidden=true] {
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
}

.relate-to_bottom-left {
	top: calc(100% + 4px);
	left: 0;
}

.relate-to_bottom-right {
	top: calc(100% + 4px);
	right: 0;
}

.full-width {
	max-width: none;
}

.display_none {
	display: none;
}
