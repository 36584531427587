.root {
	margin-bottom: 32px;
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.disclaimer {
	color: var(--vkws_landing--form-feedback-questions--text-color);
	font-size: 11px;
	font-weight: 500;
}

.feedback-questions {
	color: var(--vkws_landing--form-feedback-questions--text-color);
	font-size: 11px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
}

.feedback-questions a {
	opacity: 1;
	color: var(--vkws_landing--form-feedback-link--text-color);
	font-size: 11px;
	font-style: normal;
	font-weight: 500;
	line-height: normal;
	cursor: pointer;
}

.feedback-questions a:hover {
	text-decoration-line: underline;
}

@media screen and (min-width: 1024px) {
	.root {
		margin-bottom: 0;
	}
}
