.root {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	align-items: stretch;
	width: 100%;
	row-gap: 25px;
}

.logo {
	cursor: pointer;
	margin-left: -5px;
}

.logo svg {
	width: 175px;
	height: 28px;
}

.burger-button {
	width:32px;
	height: 32px;
	display: flex;
	justify-content: flex-end;
	cursor: pointer;
	position: relative;
}

.burger-button span {
	display: block;
	position: absolute;
	height: 2px;
	width: 20px;
	background: var(--vkws_landings--color--main_blue);
	border-radius: 0;
	opacity: 1;
	left: 6px;
	transform: rotate(0deg);
	transition: .1s var(--vkws_landings--transition--timing-function);
}

.burger-button span:nth-child(1) {
	top: 11px;
	transform-origin: left center;
}

.burger-button span:nth-child(2) {
	bottom: 11px;
	transform-origin: left center;
}

.burger-button.open span:nth-child(1) {
	transform: rotate(45deg);
	top: 8px;
	left: 8px;
}

.burger-button.open span:nth-child(2) {
	transform: rotate(-45deg);
	bottom: 8px;
	left: 8px;
}

.navbar {
	display: none;
}

.actions {
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: flex-end;
	gap: 12px;
}

.menu {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 30px;
}

.move-button {
	padding: 6px 16px 8px;
	font-size: 18px;
	outline: none;
	line-height: 26px;
	height: auto;
	border-radius: 8px;
}

.project-button {
	background: var(--vkws_landings--header_button_project--bg);
}

.project-button:hover {
	background: var(--vkws_landings--header_button_project--bg--hover);
}

.project-button:active {
	background: var(--vkws_landings--header_button_project--bg--active);
}

@media screen and (min-width: 768px) {
	.logo {
		margin-left: -2px;
	}

	.logo svg {
		width: 214px;
		height: 34px;
	}
	.move-button {
		display: flex;
		margin-left: auto;
		align-items: center;
		height: 40px;
		padding: 0 16px;
		cursor: pointer;
		font-size: 18px;
		backdrop-filter: blur(18px);
		border-radius: 8px;
	}

	.burger-button {
		margin-left: 20px;
	}
}

@media screen and (min-width: 1200px) {
	.root {
		flex-direction: row-reverse;
	}

	.burger-button {
		display: none;
	}
}

@media screen and (min-width: 1200px) {
	.logo {
		margin-left: 0;
	}
}

@media screen and (min-width: 1440px) {
	.navbar {
		display: block;
	}

	.burger-button {
		display: none;
	}

	.move-button {
		padding: 0 10px;
	}
}

@media screen and (min-width: 1540px) {
	.move-button {
		padding: 0 16px;
	}
}
